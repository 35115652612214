import jQuery from "jquery";
// import sal from "sal.js";
import lozad from 'lozad';
import _ from 'lodash';
import smoothscroll from 'smoothscroll-polyfill';
import "./main.scss";
// polyfill
smoothscroll.polyfill();

// ドルマークに参照を代入(慣習的な $ を使うため)
const $ = jQuery;


const ieFlg = false;
const edgeFlg = false;

const userAgent = window.navigator.userAgent.toLowerCase();

if(userAgent.indexOf('msie') != -1 || userAgent.indexOf('trident') != -1) {
  document.body.classList.add('ie');
  ieFlg = true;
} else if(userAgent.indexOf('edge') != -1) {
  document.body.classList.add('edge');
  edgeFlg = true;
} 

const mq = (device) => {
  if (window.matchMedia('(max-width:768px)').matches && device === 'sp') {
    return true;
  } else if (
    window.matchMedia('(min-width:769px)').matches &&
    device === 'pc'
  ) {
    return true;
  }
  return false;
}

const pageTop = () =>{
  const pt = document.querySelector('.page-top');
  
  pt.addEventListener('click', (e) => {
    e.preventDefault()
    if(ieFlg){
      window.scroll(0, top);
    }else{
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      });
    }
  }, false);

}
const SmoothScrollPc = () =>{
  const scrollLists = document.querySelectorAll('.scroll');
  for (let i of scrollLists) {

    i.addEventListener("click", (e) => { 
      for (let _i of scrollLists) {
        _i.classList.remove('active');
      }
      e.preventDefault()
      const linkUrl = e.currentTarget.href;
      e.currentTarget.classList.add('active');
      const AnckerLink = linkUrl.split('#');
      console.log(AnckerLink[1]);
      var destinationTop = document.getElementById(AnckerLink[1]).offsetTop - 60;

      if(ieFlg){
        window.scroll(destinationTop, top);
      }else{
        window.scrollTo({
          top: destinationTop,
          behavior: 'smooth'
        });
      }
     }, false);

    // i.addEventListner('click', (e)=>{
    //   e.preventDefault();
    //   console.log(e);
    // }); 
  }
  scrollLists.forEach = (e) =>{
    console.log('FFFFF'+e);
    e.currentTarget.addEventListner('click', (e)=>{
      e.preventDefault();
      console.log(e);
    }); 
  }

}
const slideFunc = () =>{
  const topSlideLists = document.querySelectorAll('.slide');
  let slideNow = 0;
  let sLideNext = 1;

  topSlideLists[slideNow].classList.add('slide-active');
  topSlideLists[sLideNext].classList.add('slide-next');

  const timerSlide = () => {

    const topSlideLists = document.querySelectorAll('.slide');

    setInterval( () => {

      for (let i of topSlideLists) {
        i.classList.remove('slide-active');
        i.classList.remove('slide-next');
      }
     
      if(slideNow < (topSlideLists.length - 1) && sLideNext < (topSlideLists.length - 1)){
        slideNow++;
        sLideNext++;
      }else if(slideNow < (topSlideLists.length - 1) && sLideNext === (topSlideLists.length - 1)){
        slideNow ++;
        sLideNext = 0;
      }else if(slideNow === (topSlideLists.length - 1) && sLideNext === 0){
        slideNow = 0;
        sLideNext ++;
      }

      topSlideLists[slideNow].classList.add('slide-active')
      topSlideLists[sLideNext].classList.add('slide-next')

    }, 7000);

  }

  setTimeout(timerSlide, 7000);

}

const ptFunc = () =>{
  const scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
  const pt = document.querySelector('.page-top');
  if(scrollTop > window.innerHeight / 2 ){
    pt.style.top = (scrollTop + window.innerHeight / 2 )+'px';
  }else{
    pt.style.top = '76vh';
  }
}

const spNaviFunc = () =>{
  const hum = document.querySelector('.menu-trigger');
  const naviLinks = document.querySelectorAll('.menu-link');
  for (let i of naviLinks) {

    i.addEventListener("click", (e) => { 
     
      e.preventDefault()
      const linkUrl = e.currentTarget.href;
      // e.currentTarget.classList.add('active');
      const AnckerLink = linkUrl.split('#');
      console.log(AnckerLink[1]);
      var destinationTop = document.getElementById(AnckerLink[1]).offsetTop - 60;
     
      window.scrollTo({
        top: destinationTop,
        behavior: 'smooth'
      });

      document.body.classList.remove('menu-show')

     }, false);

  }


  

  hum.addEventListener('click', () => {
    if(document.body.classList.contains('menu-show')){
      document.body.classList.remove('menu-show')
    }else{
      document.body.classList.add('menu-show')
    }
  }, false);

}

window.addEventListener('scroll', _.throttle(ptFunc, 500))

console.log("FFFFFFFAAAAA");
const aaa = "AAAAA";

// const func = sal({
//   rootMargin: "20% 0%"
// });
const observer = lozad('.lozad', {
  loaded: function(el) {
    // Custom implementation on a loaded element
    el.classList.add('loaded');
  }
});
observer.observe();
// SmoothScrollPc()

window.addEventListener('DOMContentLoaded', (event) => {
  pageTop()
  slideFunc()
   // PC or SPサンプル
   if (mq('sp')) {
    console.log('Mobile');
    spNaviFunc()
  } else {
    SmoothScrollPc()
    console.log('Desktop');
  }
});
